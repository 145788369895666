export const disagreementReactionExampleSideNote = `<span>(cancel culture, for example)</span>`;
export const allPartiesCrustySideNote = `<span>(and people of all political parties)</span>`;
export const jdVanceResearchSideNote = `<span>(including the <a target="_blank" rel="noopener noreferrer" href="https://www.kenklippenstein.com/api/v1/file/fc39e78d-f510-4918-935b-95701be97310.pdf">finding</a> that “In 2017, Vance criticized Trump for saying ‘half-truths or lies’ so frequently ‘that you stop actually taking the president literally.’”)</span>`;
export const muskCensorshipSideNote = `<span>Twitter said the suspension was for posting private personal information, even though: all the personal information in the document is publicly available online; the Hunter Biden leak had even more sensitive information, including banking information; and when the leaker <a target="_blank" rel="noopener noreferrer" href="https://www.kenklippenstein.com/p/my-twitter-ban-is-political">redacted</a> Vance’s personal information a day later, the suspension was not overturned.</span>`;
export const acceptingDisagreementSideNote = `<span>(yes, even on the most important issues, even when all the facts right in front of you show that their position is disastrously harmful)</span>`;
export const antiTransCrustySideNote = `<span>And as with gay marriage, just because you’re against trans rights doesn’t mean you’re crusty.</span>`;
export const transRightsSideNote = `<span>The trans rights movement also shows how people can have the right overall goal, but be wrong in certain policy positions relating to that goal. My belief is that trans women shouldn't compete in women's sports, because their sex gives them an unfair competitive advantage over the other athletes, and competing in sports isn't necessary for living life as a woman. In contrast, I'm completely supportive of trans women winning an Oscar in the best actress category, because there's no competitive advantage.</span>`;
export const takeiTweetSideNote = `<span>There's an easy answer: he's showing an intellectual blindspot. Knowing that the vaccine saves lives made him lose patience for people’s concerns about the vaccine.</span>`;
export const understandReluctanceSideNote = `<span>Although I understand people’s objections to voting for Harris, given immigration, inflation, international conflict, and more during Biden’s term. It’s not like one candidate is always right and the other is always wrong.</span>`;
export const moreTrumpCrustyExamplesSideNote = `<span>Other examples include waiting <a target="_blank" rel="noopener noreferrer" href="https://www.foxnews.com/video/6309950724112">over three hours</a> before telling the people invading the Capitol Building (and killing a police officer) to go home, leaving the country unprepared for covid by <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/live/NbwCjL7HC1c?si=DLoHVpJuSY-ABS3j&amp;t=353">denying the danger it posed</a>, insisting <a target="_blank" rel="noopener noreferrer" href="https://rumble.com/v11w8xm-president-donald-trump-delivers-keynote-speech-in-florida.html?start=3100">the impact of climate change is a hoax</a> despite storms getting more destructive every year, wanting to make it possible to <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/watch?v=EUoqbbneOFc">sue news organizations</a> for being critical of him, <a target="_blank" rel="noopener noreferrer" href="https://www.pbs.org/newshour/politics/photos-from-trump-indictment-show-boxes-of-classified-documents-stored-in-mar-a-lago-shower-ballroom">hoarding top-secret documents</a> in public places in his home, <a target="_blank" rel="noopener noreferrer" href="https://www.foxnews.com/politics/obamacare-straight-repeal-fails-on-senate-vote">attempting to repeal</a> Obamacare, causing the <a target="_blank" rel="noopener noreferrer" href="https://www.reuters.com/article/world/backing-down-trump-agrees-to-end-shutdown-without-border-wall-money-idUSKCN1PJ2DJ">longest government shutdown</a> in U.S. history, and filing for bankruptcy <a target="_blank" rel="noopener noreferrer" href="https://www.politifact.com/factchecks/2016/jun/21/hillary-clinton/yep-donald-trumps-companies-have-declared-bankrupt/">six times</a>.</span>`;
export const laptopMisinformationSideNote = `<span>JD Vance has spread the misinformation that censoring the Hunter Biden laptop story cost Trump millions of votes. That number comes from a partisan <a target="_blank" rel="noopener noreferrer" href="https://www.mrc.org/">team</a> (their homepage says, “MRC leads the conservative movement in combatting the left’s efforts to manipulate the electoral process”) who didn't even ask specifically about Hunter Biden's emails in their <a target="_blank" rel="noopener noreferrer" href="https://cdn.mrc.org/TPC-MRC+Biden+Voter+Messaging+Survey+Analysis+Nov+2020_final.pdf/">survey</a>. It would be like a Democrat staking all their credibility on a biased MSNBC poll.</span>`;
export const respectingChoiceSideNote = `<span>I hope you can tell by now that I really mean that.</span>`;
export const peopleIAgreeWithSideNote = `<span>This obviously also goes for people who I agree with – for people who want to protect abortion rights and who want to keep the lizards in power, I support them voting for Harris. However, for friends who want to reduce the death and suffering of Palestinians, I don’t support them voting for Trump or choosing not to vote. It goes against their values, because a Trump presidency would only lead to more death and suffering of Palestinians. I understand that the goal is to send a message to Democrats, but it sends an even stronger message to Republican politicians that their destructive policies can continue. The same applies for reducing the death and suffering of Israelis.</span>`;
export const notCriticizingSupportersSideNote = `<span>My criticisms are directed at Trump, not his supporters.</span>`;

export const crustyExamples = [
  `<span>Not accepting evidence that goes against your beliefs</span>`,
  `<span>Spreading misinformation</span>`,
  `<span>Not accepting any nuance</span>`,
  `<span>Not taking into account the impact of your actions, regardless of how good your intentions are</span>`,
  `<span>Not giving people the benefit of the doubt</span>`,
  `<span>Mischaracterizing other groups of people</span>`,
  `<span>Treating people who disagree with you as if they don’t deserve respect (insults, ostracism, inconsistent standards, etc.)</span>`
]

export const previewToTrumpExamples = [
  `Preventing Democrats from securing the border`,
  `Imposing tariffs that will make everything more expensive again just as inflation is <a target="_blank" rel="noopener noreferrer" href="https://www.bls.gov/charts/consumer-price-index/consumer-price-index-by-category-line-chart.htm">back under control</a>`,
  `Escalating conflicts in the Middle East`,
]

export const trumpExamples = [
  `When Biden and Harris tried to pass a bipartisan bill for securing the border that included <a target="_blank" rel="noopener noreferrer" href="https://www.foxnews.com/politics/border-hawks-blast-gop-dissenters-not-supporting-bill-illegal-immigration">many of Trump’s own policies</a>, Trump rallied Republicans <a target="_blank" rel="noopener noreferrer" href="https://www.axios.com/2024/01/29/trump-republicans-border-deal-senate-immigration">to stop the bill from passing</a>. He had a chance to explain his reasoning but <a target="_blank" rel="noopener noreferrer" href="https://www.youtube.com/live/MAm5OCgZ6so?t=5290s">dodged the question</a>.`,
  `His trade war with China <a target="_blank" rel="noopener noreferrer" href="https://www.aeaweb.org/articles?id=10.1257/jep.33.4.187">raised prices</a>, and he plans to <a target="_blank" rel="noopener noreferrer" href="https://www.usatoday.com/story/news/politics/elections/2024/08/15/donald-trump-twenty-percent-tariff-economic-policy/74809155007/">double down on those tariffs</a> if elected.`,
  `He declared Jerusalem as the capital of Israel, leading to Hamas calling for renewed violence against Israel, as well as the U.S.-backed Palestinian president saying the U.S. could <a target="_blank" rel="noopener noreferrer" href="https://www.fec.gov/resources/cms-content/documents/federalelections2020.pdf">no longer mediate</a> peace efforts.`,
]